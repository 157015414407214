import * as React from "react";
import "./Header.scss"
import {BottomNavigation, BottomNavigationAction, Box} from "@mui/material";
import {Link} from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import GavelIcon from '@mui/icons-material/Gavel';
import InfoIcon from '@mui/icons-material/Info';
import BookIcon from '@mui/icons-material/Book';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import logo from '../public/logo.png'


export default class Header extends React.Component {
  //  navigate = useLocation();

    constructor(props) {
        super(props);
        this.state = {value: props.view};
        console.log(this.state.value);
    }



    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        if(nextProps.view !== this.state.view) {
            return true;
        }
        console.log("Test")
        return false;
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.view !== prevProps.view) {
            this.setState({value: this.props.view});
        }
        console.log(prevProps.view)
    }

    handleChangeMenuBar = (newValue: string) => {
        this.setState({value: newValue});
    }

    render() {
        return (
           <div className={"Header"}>
               <div className={"Logo"}>
                   <img src={logo}/>
               </div>

               <div className={"HeaderRight"} >
                   <div className={"Title"}>
                        WIR in Bruchsal
                   </div>
                   <div className={"Menu"}>
                       <Box sx={{fontWeight: 'light',fontSize:"7px",minWidth:"max-content",backgroundColor:"#47d6ef"}} >
                       <BottomNavigation color={"black"} showLabels sx={{maxWidth:"100%",backgroundColor:"#47d6ef"}} value={this.state.value} onChange={(event, newValue) => this.handleChangeMenuBar(newValue)}>
                           <BottomNavigationAction
                               component={Link}
                               label="Start"
                               value="/start"
                               icon={<HomeIcon/>}
                               to={"/start"}
                           ></BottomNavigationAction>
                           <BottomNavigationAction
                               component={Link}
                               label="Aktivitäten"
                               value="/activities"
                               icon={<DirectionsRunIcon/>}
                               to={"/activities"}
                           ></BottomNavigationAction>
                           <BottomNavigationAction
                               label="Impressum"
                               value="/impressum"
                               icon={<InfoIcon/>}
                               to={"/impressum"}
                               component={Link}
                           />
                           <BottomNavigationAction
                               label="Präambel"
                               value="/praeambel"
                               icon={<BorderColorIcon/>}
                               to={"/praeambel"}
                               component={Link}
                           />
                           <BottomNavigationAction
                               label="AGBs"
                               value="/agbs"
                               icon={<GavelIcon/>}
                               to={"/agbs"}
                               component={Link}
                           />
                           <BottomNavigationAction
                               component={Link}
                               label="Datenschutzerklärung"
                               value="/privacy"
                               icon={<BookIcon/>}
                               to={"/privacy"}
                           />
                       </BottomNavigation>
                       </Box>
                   </div>
               </div>
        </div>
        )
    }

}
