import * as React from "react";
import "../../App.css"

export default class Praeambel extends React.Component {

    render() {
        return (
            <div className={"Content"}>
                <h2>Präambel</h2>
                <h3>Wer wir sind</h3>
                <p>Wir wollen reden!</p>
                <p>Wir erleben ein Störgefühl, weil vieles nicht mehr rund läuft in unserem Land, in Europa und in der Welt.</p>
                <p>Wir spüren eine Unruhe, doch wir können sie nicht fassen oder begreifen.</p>
                <p>Wir stehen für eine Gemeinschaft, die versucht, im kleinen Kreis die Dinge zu ändern, die wir ändern können und mit den Dingen zu leben, die wir nicht ändern können.</p>
                <h3>Tucholsky</h3>
                <p>Das Volk versteht das meiste falsch; aber es fühlt das meiste richtig.</p>
                <h3>Unsere Werte</h3>
                    <h4>1. Demokratie</h4>
                    <p>Direkte Demokratie</p>
                    <a href={"https://www.gesetze-im-internet.de/gg/BJNR000010949.html"}>Grundgesetz</a>
                    <h4>2. Ethik</h4>
                    <a href={"https://www.un.org/depts/german/menschenrechte/aemr.pdf"}>UN-Menschenrechtscharta</a>
                    <p>Gedanke der Menscheitsfamilie</p>
                    <h4>3. Gemeinwohlökonomie</h4>
                    <p>Bildung</p>
                    <p>Gesundheit darf keine Ware werden</p>
            </div>
        );
    }
}