import * as React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./Start.scss"
import ReactPlayer from 'react-player/lazy'


export default class Start extends React.Component {

    render() {
        return(
            <div className="body">
                <Accordion defaultExpanded>
                    <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <Typography>Windkraftbau im Raum Bruchsal </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Accordion defaultExpanded>
                            <AccordionSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel1-content"
                                          id="panel1-header"
                                        >
                                          <Typography>06.07.2024 Pro & Contra Veranstaltung in Heidelsheim</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                                <ReactPlayer url='/video/Pro & Contra Veranstaltung.mp4'
                                                  config={{
                                                    file: {
                                                      playerVars: { showinfo: 1 }
                                                    },
                                                  }}
                                                  controls={true}
                                                  width='100%'
                                                  light={<img className="thumbnail" src='/video/Pro & Contra Veranstaltung.jpg'  alt='Thumbnail'/>}
                                                />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary
                               expandIcon={<ExpandMoreIcon />}
                               aria-controls="panel1-content"
                               id="panel1-header"
                               >
                                    <Typography>18.07.2023 Bürgerinformationsabend in Bruchsal</Typography>
                                       </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Typography>
                                                                        <ReactPlayer url='/video/Windkraft im Binnenland.mp4'
                                                                          config={{
                                                                            file: {
                                                                              playerVars: { showinfo: 1 }
                                                                            },
                                                                          }}
                                                                          controls={true}
                                                                          light={<img className="thumbnail" src='/video/Windkraft im Binnenland.jpg' alt='Thumbnail'/>}
                                                                          width='100%'
                                                                        />
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2-content"
                      id="panel2-header"
                    >
                      <Typography>Weitere Themen</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Weitere Themen folgen in Kürze!
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
        )
    }
}