import * as React from "react";
import "../../App.css"

export default class AGBs extends React.Component {

    render() {
        return(
            <div className={"Content"}>
                <h2>AGB</h2>
                <p>Die Bürgerinitiative "WIR in Bruchsal" bietet keinerlei Lieferungen oder Leistungen an.</p>
            </div>
        )
    }
}