import * as React from "react";
import "./Footer.scss"

export default class Footer extends React.Component {

    render() {
        return(
            <div className={"Footer"}>
                <p>@Copyright 2023 WIR in Bruchsal</p>
            </div>
        )
    }
}