import './App.css';
import Header from "./header/Header";
import * as React from "react";
import {
    Route,
    BrowserRouter, Navigate, Routes,
} from "react-router-dom";
import Start from "./views/start/Start";
import AGBs from "./views/agbs/AGBs";
import Footer from "./footer/Footer";
import Privacy from "./views/privacy/Privacy";
import Impressum from "./views/impressum/Impressum";
import Activities from "./views/activities/Activities";
import Praeambel from "./views/praeambel/Praeambel";

export default class App extends React.Component{

    constructor(props) {
        super(props);
        this.state = {view: "0"};
        this.i = 0;
    }

    setView(view: string) {
        //this.setState({view: view});
    }


    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <div className={"ContentArea"}>
                <Routes>
                    <Route path="start" element={<div><Header view={"/start"}></Header><Start/></div>}/>
                    <Route path="activities" element={<div><Header view={"/activities"}></Header><Activities/></div>}/>
                    <Route path="impressum" element={<div><Header view={"/impressum"}></Header><Impressum/></div>}/>
                    <Route path="agbs" element={<div><Header view={"/agbs"}></Header><AGBs/></div>}/>
                    <Route path="privacy" element={<div><Header view={"/privacy"}></Header><Privacy/></div>}/>
                    <Route path="praeambel" element={<div><Header view={"/praeambel"}></Header><Praeambel/></div>}/>
                    <Route path='*' element={<div><Header view={"/start"}></Header><Start/></div>} />
                </Routes>
                    </div>
                </BrowserRouter>
                <Footer></Footer>
            </div>
        );
    }
}
