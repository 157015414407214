import * as React from "react";
import "../../App.css"

export default class Activities extends React.Component {

    render() {
        return(
            <div className={"Content"}>
                <h2>Aktivitäten</h2>
                <p>In Kürze mehr...</p>
            </div>
        )
    }
}